<template>
  <v-container fluid>
    <v-layout column>
      <v-flex xs12>
        <v-card class="subheading pa-3 mt-3">
          <div class="title ma-2" style="display: flex;">
            <span>Result Lot List</span>&nbsp;&nbsp;&nbsp;
            <v-divider class="my-6" vertical></v-divider>
            <v-spacer></v-spacer>
            <v-layout row justify-end>
              <v-dialog v-model="dialog" persistent max-width="900px">
                <template v-slot:activator="{ on }">
                  <v-btn small dark color="#3F51B5" v-on="on">
                    <v-icon size="30" color="#fff">add</v-icon> New Result Lot
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title style="background-color:#3F51B5; color:#fff;">
                    <span class="headline">Insert Result Lot</span>
                    <v-spacer></v-spacer>
                    <v-btn small fab dark color="red" @click="closeDia">
                      <v-icon size="22" color="#fff">close</v-icon>
                    </v-btn>
                  </v-card-title>
                  <v-card-text>
                    <v-container grid-list-md>
                      <v-form>
                        <v-layout wrap>
                          <v-flex xs12 sm6 md12>
                            <v-text-field
                              label="Table Name"
                              v-model="searchParam.table_name"
                              prepend-icon="edit"
                              outlined
                            ></v-text-field>
                          </v-flex>
                          <v-flex xs12 sm6 md6>
                            <v-text-field
                              label="Installment No"
                              v-model="searchParam.installment"
                              type="number"
                              prepend-icon="edit"
                              outlined
                            ></v-text-field>
                          </v-flex>
                          <v-flex xs6 sm6>
                            <v-select
                              :items="items_status"
                              item-text="key"
                              item-value="value"
                              v-model="searchParam.result_type"
                              label="Select Result Type"
                              prepend-icon="list"
                              outlined
                            ></v-select>
                          </v-flex>
                        </v-layout>
                      </v-form>
                    </v-container>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn dark color="#3F51B5" @click="saveResLotData"
                      >Save</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-layout>
            <v-btn fab small dark color="#3F51B5" @click="getResultDataSet()" style="margin-top:-1px;">
              <v-icon size="30" color="#fff">refresh</v-icon>
            </v-btn>
          </div>
          <v-data-table
            :headers="headers"
            :items="getLotData"
            class="elevation-1"
            :loading="loading"
            hide-actions
          >
            <template v-slot:items="{ item, index }">
              <tr>
                <td class="text-xs-center">{{ index + 1 }}</td>
                <td class="text-xs-left">{{ item.table_name }}</td>
                <td class="text-xs-center">{{ item.inst }}</td>
                <td class="text-xs-left">{{ item.result_type }}</td>
                <td class="text-xs-center">{{ item.total_students }}</td>
                <td class="text-xs-center">{{ item.total_inserted }}</td>
                <td class="text-xs-center">Action</td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import commonGet from "../../mixin/common_getters_mixin";
import commonExamYear from "../../mixin/exam_year_watch_mixin";
import common_login_getters from "../../mixin/common_login_getters";

export default {
  mixins: [commonGet, commonExamYear, common_login_getters],
  data() {
    return {
      loading: false,
      searchParam: {},
      dialog: false,
      headers: [
        { text: "Sl", value: "id" },
        { text: "Table Name", value: "table_name" },
        { text: "Installment", value: "inst" },
        { text: "Result Type", value: "result_type" },
        { text: "Total Students", value: "total_students" },
        { text: "Total Inserted", value: "total_inserted" },
        { text: "Action", value: "action" },
      ],
      items_status: [
        { key: "Correction", value: "correction" },
        { key: "New", value: "new" },
      ],
    };
  },
  computed: {
    getLotData() {
      return this.$store.getters.getResultLotList;
    },
  },
  created() {
    this.getResultDataSet();
  },
  methods: {
    getResultDataSet() {
      this.loading = true;
      let param = {
        exam_initiate_id: this.getExamInitiateId,
        exam_code: this.getLoggedInExam.code,
        exam_year: this.getLoggedInYear.year,
      };
      this.$store.dispatch("fetchresLotList", param).then(() => {
        this.loading = false;
      });
    },
    saveResLotData() {
      if (
        !this.searchParam.table_name ||
        !this.searchParam.installment ||
        !this.searchParam.result_type
      ) {
        this.$store.commit("SET_SNACKBAR", {
          msg: "All fields are required!",
          color: "red",
        });
        return;
      }
      const payload = {
        ...this.searchParam,
        exam_code: this.getLoggedInExam.code,
        exam_year: this.getLoggedInYear.year,
        status: 1,
      };
      this.$store.dispatch("storeResLotData", payload).then(() => {
        this.$store.commit("SET_SNACKBAR", {
          msg: "Data inserted successfully!",
          color: "green",
        });
        this.getResultDataSet();
        this.closeDia();
      });
    },
    closeDia() {
      this.searchParam = {};
      this.dialog = false;
    },
  },
};
</script>

<style scoped></style>
